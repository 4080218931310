<template>
  <Layout>
    <WithLocation
      v-slot="{ isLocationLoading, theLocation, theFeaturedImage }"
      :slug="locSlug"
    >
      <BaseLoader v-if="isLocationLoading" bg-color="#fff" color="#CA6FDA"
        ><h3 class="text-xs-center mt-4 bluegiant-light-blue--text"
          >Loading the menu...</h3
        ></BaseLoader
      >
      <div v-else-if="!theLocation" class="not-found">
        <BasePageSection bg-color="#CA6FDA">
          <VLayout justify-center>
            <VFlex xs6>
              <h3 class="r-headline"
                >Sorry, but we couldn't find that location. Pick one below or
                <BaseButton flat link small color="green darken-2" to="/contact"
                  >get in touch</BaseButton
                >
                if you need assistance.</h3
              >
            </VFlex>
          </VLayout>
        </BasePageSection>
      </div>
      <div v-else class="single-location">
        <BaseThemeComponent
          name="SingleLocationHeader"
          :bg-image="theFeaturedImage ? theFeaturedImage : ''"
          :title="theLocation.title"
          :location="theLocation"
        />
        <BasePageSection
          v-if="theLocation.status !== 'coming_soon'"
          id="menu"
          py-2
          fluid
          full-width
          class="bubs-menu-section"
        >
          <VLayout row justify-center>
            <VFlex xs12>
              <BaseLocationContent
                content-key="menu_title"
                default-content="Our Menu"
                tag-class="header-4 text-xs-center mb-4 bubs-charcoal--text"
                tag="h1"
              /> </VFlex
          ></VLayout>
          <WithLayout
            v-slot="{ isLayoutLoading, theLayout }"
            :slug="`${locSlug}-menu`"
          >
            <LayoutGrid
              v-if="!isLayoutLoading"
              :location="theLocation"
              :layout="theLayout"
              :class="`${locSlug}-menu bubs-menu`"
            />
          </WithLayout>
          <!-- <BaseLocationMenus :location="theLocation" /> -->
        </BasePageSection>
        <BaseThemeComponent
          v-if="getSiteContentByKey('happy_hour_cta_title')"
          name="PageSection"
          :bg-image="getSiteContentByKey('happy_hour_cta_bg')"
        >
          <VLayout row justify-center text-xs-center text-sm-left>
            <VFlex xs12 sm8>
              <BaseSiteContent
                tag="h1"
                tag-class="white--text header-4"
                content-key="happy_hour_cta_title"
                default-content="Bub's Happy Hour"
              />
              <BaseSiteContent
                tag="p"
                tag-class="r-headline white--text"
                content-key="happy_hour_cta_body"
                default-content="$5 Bub Burgers, $7 Impossible Burgs and $1 off all sides (Happy Hour offered only at the banks location)"
              />
            </VFlex>
          </VLayout>
        </BaseThemeComponent>
        <BaseThemeComponent
          name="SectionLocations"
          title="Bub's Other Location"
          :filter="excludeThisLocation"
        />
        <BaseThemeComponent name="CateringCTA" />
      </div>
    </WithLocation>
    <BaseMenuComponent name="MenuSnackbar" />
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@utils/get-layout'
import formatPhone from '@utils/format-phone'
import LayoutGrid from '@components/Layout/LayoutGrid'

export default {
  name: 'BubsSingleLocation',
  components: { Layout, LayoutGrid },
  props: {
    locSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEmailModal: false,
    }
  },
  computed: {
    ...mapGetters('site', ['getSiteContentByKey']),
  },
  methods: {
    formatPhone,
    excludeThisLocation(loc) {
      return loc.slug !== this.$route.params.locSlug
    }, // excludeThisLocation
  },
}
</script>

<style lang="scss">
@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}

.bubs-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left right .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'right' !important;
      grid-template-columns: 1fr !important;
    }
  }
}

.bubs-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
