var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('WithLocation',{attrs:{"slug":_vm.locSlug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLocationLoading = ref.isLocationLoading;
var theLocation = ref.theLocation;
var theFeaturedImage = ref.theFeaturedImage;
return [(isLocationLoading)?_c('BaseLoader',{attrs:{"bg-color":"#fff","color":"#CA6FDA"}},[_c('h3',{staticClass:"text-xs-center mt-4 bluegiant-light-blue--text"},[_vm._v("Loading the menu...")])]):(!theLocation)?_c('div',{staticClass:"not-found"},[_c('BasePageSection',{attrs:{"bg-color":"#CA6FDA"}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs6":""}},[_c('h3',{staticClass:"r-headline"},[_vm._v("Sorry, but we couldn't find that location. Pick one below or "),_c('BaseButton',{attrs:{"flat":"","link":"","small":"","color":"green darken-2","to":"/contact"}},[_vm._v("get in touch")]),_vm._v(" if you need assistance.")],1)])],1)],1)],1):_c('div',{staticClass:"single-location"},[_c('BaseThemeComponent',{attrs:{"name":"SingleLocationHeader","bg-image":theFeaturedImage ? theFeaturedImage : '',"title":theLocation.title,"location":theLocation}}),(theLocation.status !== 'coming_soon')?_c('BasePageSection',{staticClass:"bubs-menu-section",attrs:{"id":"menu","py-2":"","fluid":"","full-width":""}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":""}},[_c('BaseLocationContent',{attrs:{"content-key":"menu_title","default-content":"Our Menu","tag-class":"header-4 text-xs-center mb-4 bubs-charcoal--text","tag":"h1"}})],1)],1),_c('WithLayout',{attrs:{"slug":(_vm.locSlug + "-menu")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLayoutLoading = ref.isLayoutLoading;
var theLayout = ref.theLayout;
return [(!isLayoutLoading)?_c('LayoutGrid',{class:(_vm.locSlug + "-menu bubs-menu"),attrs:{"location":theLocation,"layout":theLayout}}):_vm._e()]}}],null,false,2710172689)})],1):_vm._e(),(_vm.getSiteContentByKey('happy_hour_cta_title'))?_c('BaseThemeComponent',{attrs:{"name":"PageSection","bg-image":_vm.getSiteContentByKey('happy_hour_cta_bg')}},[_c('VLayout',{attrs:{"row":"","justify-center":"","text-xs-center":"","text-sm-left":""}},[_c('VFlex',{attrs:{"xs12":"","sm8":""}},[_c('BaseSiteContent',{attrs:{"tag":"h1","tag-class":"white--text header-4","content-key":"happy_hour_cta_title","default-content":"Bub's Happy Hour"}}),_c('BaseSiteContent',{attrs:{"tag":"p","tag-class":"r-headline white--text","content-key":"happy_hour_cta_body","default-content":"$5 Bub Burgers, $7 Impossible Burgs and $1 off all sides (Happy Hour offered only at the banks location)"}})],1)],1)],1):_vm._e(),_c('BaseThemeComponent',{attrs:{"name":"SectionLocations","title":"Bub's Other Location","filter":_vm.excludeThisLocation}}),_c('BaseThemeComponent',{attrs:{"name":"CateringCTA"}})],1)]}}])}),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }